@import "./theme-settings.scss";

.add-new-styles-here {
  color: blue;
}

.main-navbar .logo span {
  display: none;
}

@media screen and (min-width: 400px) and (max-width: 768px) {
  .mini-header img {
    width: 330px;
  }
}

@media screen and (min-width: 576px) and (max-width: 950px) {
  .main-navbar .logo img {
    width: 300px;
  }
}
